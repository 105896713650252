:focus {
  outline: 0;
}

body {
  opacity: 0;
  transition: 0.5s all ease;
}

body.active {
  opacity: 1!important;
}

$primary: #000;


h1,h2,h3,h4,h5 {
  font-family: 'Playfair Display', serif;
}

p {
  font-family: 'Playfair Display', serif;
}

.gotham {
  font-family: 'gotham book', serif;
}

a {  
  font-size: 14px;
  color: #000;  
  transition: 0.2s all ease;

  &:hover {
    color: #086c98;    
  }

  &.light:hover {
    color: white;     
  }
}

.cls-1{
  fill:#fff;
  transition: all 0.4s ease;
}

header {
  position: fixed;
  top: 0;
  padding: 34px 20px;
  z-index: 20;
  width: 100%;
  background-color: transparent;
  transition: all 0.4s ease;

  @include breakpoint(large) {
    padding: 34px 87px;
  }

  &.scroll {    
    padding-top: 15px;
    padding-bottom: 15px;

    background-color: white;

    .cls-1{
      fill:#000;
    }
    nav {
      a {
        color: white;

        @include breakpoint(large) {
          color: $primary;
        }
      }      
    }

    

  }

  &:after {
    content: ' ';
    clear:both;
    height: 0;
    display: table;
  }
  #logo {
    width: 184px;
    display: block;
    float: left;
    img {
      width: 100%;
    }
  }


  nav {
    float: none;    
    position: fixed;    
    top: -100vh;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #414042;
    transition: 0.5s all ease;

    &.active {
      top: 0;
    }

    @include breakpoint(large) {
      float: right;      
      position: static;

      width: auto;
      height: auto;
      background-color: transparent;
    }

    a {      
      font-family: 'Playfair Display', serif;
      // text-transform: uppercase;
      font-size: 23px;
      color: #fff;
      // letter-spacing: 3.67px;
      text-align: center;
      display: block;
      margin: 8vh auto 0;


      @include breakpoint(large) {        
        margin: 0 0 0 45px;
        color: #fff;
        font-size: 15px;
        display: inline;

        text-align: left;
      }
    }
  }
}

.bg-grey {
  background-color: #f5f5f5;
}

.bg-white {
  background-color: white;
}

.bg-black {
  background-color: black;
}

.cus1 {
  
  padding: 20px 10px;
  margin-top: 0;
  z-index: -1;
  position: relative;

  @include breakpoint(large) {
    padding: 210px 4em 50px 8em;
    margin-top: -150px;
  }
}

.cus2 {  
  padding:20px 10px;
  margin-top: 0;

  @include breakpoint(large) {
    padding: 50px 8em 50px 4em;
    margin-top: -150px;
  }
}

h1 {  
  font-size: 30px;  
  @include breakpoint(large) {font-size: 40px;  }
    

}

h2 {  
  font-size: 14px;  
  line-height: 35px;
  @include breakpoint(medium) {
    line-height: 50px;
    font-size: 22px;
  }
}

h3 {  
  font-size: 20px;
  color: #414042;
  letter-spacing: 6px;
  text-align: center;
  line-height: 50px;
}

h4 {  
  font-size: 20px;
  color: #D9D8D6;
  letter-spacing: 2px;
  margin-bottom:40px;
}

p,  {  
  font-size: 14px;
  line-height: 27px;

  &.large {
    @include breakpoint(medium) {    
      font-size: 30px;
      line-height: 1.3;
    }    
  }

  &.gotham {
    @include breakpoint(medium) {    
      font-size: 15px;
      line-height: 26px;
    }     
  }

  @include breakpoint(medium) {    
    font-size: 20px;
    line-height: 32px;
  }
}

.spacing20 {
  height: 20px;
}

.spacing40 {
  height: 40px;
}

.spacing60 {
  height: 60px;
}

.spacing80 {
  height: 80px;
}

.spacing100 {
  height: 100px;
}

.relative {
  position: relative;
  overflow:hidden;
}

.row {
  // overflow: hidden;
}

.section1 {}

// .row {
//   padding: 80px 0;

//   &.no-padding {
//     padding: 0;
//   }
// }



.section2 {  
}

.light {
  color: white;
}

.center-center {
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.h-center {
  position:absolute;
  left: 50%;  
  transform: translateX(-50%);
}

@media screen and (max-width: 1112px) {}


input[type="email"] {
  background-color:transparent;
  border: 0;
  border-bottom: 2px solid #D8D8D8;
  width: 300px;
  margin-right: 30px;
  padding-bottom: 5px;
  display: inline-block;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    background-color:transparent;
    border: 0;
    border-bottom: 2px solid #D8D8D8;
  }
}

input::-webkit-input-placeholder {
    color: #414042;
    opacity: 1;
}

input::-moz-placeholder {
    color: #414042;
    opacity: 1;
}

input::-ms-placeholder {
    color: #414042;
    opacity: 1;
}

input::placeholder {
    color: #414042;
    opacity: 1;
}

.submit {  
  font-size: 16px;
  color: #D9D8D6;
  letter-spacing: 13.33px;
  line-height: 50px;
  position: relative;
  top: 13px;

}

.align-bottom {  
  @include breakpoint(medium) {
    display: flex;
    align-items: flex-end;
  }
}

.fade-up {
  transform: translateY(15px);
  opacity: 0;
  transition: 0.9s all ease;
  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-left {
  transform: translateX(-15px);
    opacity: 0;
    transition: 0.9s all ease;
    &.active {
      transform: translateX(0);
      opacity: 1;
    }
}

.fade-right {
  transform: translateX(15px);
    opacity: 0;
    transition: 0.9s all ease;
    &.active {
      transform: translateX(0);
      opacity: 1;
    }
}

.fade-delay {
  transition-delay: 0.2s;
}

.gradient {
  position: absolute;
  top: 0;
  height: 65%;
  width: 100%;
  background: rgb(255,255,255);  
  background: linear-gradient(180deg, rgba(255,255,255,1) 65%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
  z-index: -1;
}

$tcon-size: 30px !default;
$tcon-transition: .3s !default;
$tcon-jstransform: 'tcon-transform' !default;

$tcon-menu-radius: ($tcon-size / 14) !default;
$primary-color: #a20060 !default;
$tcon-menu-arrowleft-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 90deg)
) !default;
$tcon-menu-arrow360left-transformation: (
  transform: scale3d(.8, .8, .8) rotate3d(0, 0, 1, 360deg)
) !default;
.tcon {
  position: relative;
  top: -3px;
  right: 0;
  float:right;
  z-index: 99999;
  appearance: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: $tcon-size;
  transition: $tcon-transition;
  user-select: none;
  width: $tcon-size;
  background: transparent;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;

  @include breakpoint(large) {
    display: none;
  }

  > * {
    display: block;
  }

  &:hover,
  &:focus {
    outline: none; // see issue #36 https://github.com/grayghostvisuals/transformicons/issues/36
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

@mixin tcon-menu-lines {
  display: inline-block;
  height: ($tcon-size / 15);
  width: $tcon-size;
  border-radius: $tcon-menu-radius;
  transition: $tcon-transition;
  background: #fff;  
}

.tcon-menu__lines {
  @include tcon-menu-lines;
  position: relative;

  &::before,
  &::after {
    @include tcon-menu-lines;
    content: '';
    position: absolute;
    left: 0;
    transform-origin: ($tcon-size / 14) center;
    width: 100%;
  }

  &::before {
    top: ($tcon-size / 4);
  }

  &::after {
    top: -($tcon-size / 4);
  }

  .#{$tcon-jstransform} & {
    transform: scale3d(.8, .8, .8);
  }
}
.tcon-menu--xcross {
  width: auto;

  &.#{$tcon-jstransform} {
    .tcon-menu__lines {
      background: transparent;

      &::before,
      &::after {
        transform-origin: 50% 50%;
        top: 0;
        width: $tcon-size;
      }

      &::before {
        transform: rotate3d(0, 0, 1, 45deg);
      }

      &::after {
        transform: rotate3d(0, 0, 1, -45deg);
      }
    }
  }
}
// see issue #7 https://github.com/grayghostvisuals/transformicons/issues/7#issuecomment-59813596
.tcon-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  &:active,
  &:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
  }
}

.scroll {
  .tcon-menu__lines,.tcon-menu__lines::before, .tcon-menu__lines::after {
    background: #000;
  }
}
  

.button {
  margin-top: 15px;
  display: inline-block;
  &__gold {
    background-color:#A27300;
    &:hover {
      background-color: black;
    }
  }
}

@keyframes move {
  0% {
    -webkit-transform-origin: bottom left;
    -moz-transform-origin: bottom left;
    -ms-transform-origin: bottom left;
    -o-transform-origin: bottom left;
    transform-origin: bottom left;
    transform: scale(1.0);
    -ms-transform: scale(1.0);
    /* IE 9 */
    
    -webkit-transform: scale(1.0);
    /* Safari and Chrome */
    
    -o-transform: scale(1.0);
    /* Opera */
    
    -moz-transform: scale(1.0);
    /* Firefox */
  }
  100% {
    transform: scale(1.2);
    -ms-transform: scale(1.2);
    /* IE 9 */
    
    -webkit-transform: scale(1.2);
    /* Safari and Chrome */
    
    -o-transform: scale(1.2);
    /* Opera */
    
    -moz-transform: scale(1.2);
    /* Firefox */
  }
}

.slides {
  overflow: hidden;
}

.slide-content {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;   
}

.vertical-text {
  font-family: 'Playfair Display', serif;
  position: absolute;
  font-size: 13px;
  transform-origin: center left;
  color: #000;
  z-index: 1;
  position: absolute;
  display: none;
  transform: rotate(-90deg);

  @include breakpoint(large) {
    display: block;
  }

  &.light {
    color: white;

    hr {
      border-bottom: 1px solid white;
    }
  }

  hr {
    display: inline-block;
    border: 0;
    width: 50px;
    border-bottom: 1px solid black;
    margin: 0 10px 3px 0px;
  }
  
}

#home {

  .vertical-text {    
    left: 50%;  
    transform: translateX(-50%) rotate(-90deg);
    bottom: -12px;
  }

  .slides {    

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 68%;
      text-shadow: 2px 4px 5px rgba(0,0,0,0.9);

      @include breakpoint(large) {
        top: 50%;
        left: 65%;
        transform: translate(-50%, -50%);        
        width: 50%;
      }
    }

    .slide-content {
      width: 100%;
      height: calc(80vh);  
      @include breakpoint(large) {
        height: calc(100vh + 1px);   
      }              
    }
    .slide-content1 {
      background-image: url('/assets/images/hero/1.jpg');
    }
    .slide-content2 {
      background-image: url('/assets/images/hero/2.jpg');
    }

    .slide-content3 {
      background-image: url('/assets/images/hero/3.jpg');
    }

    .slick-active {
      .slide-content {
        animation: move 40s ease;  
      }
    }
  }  


  


}

.slick-dots {
  margin: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  li {
    list-style-type: none;
    color: white;
    margin-bottom: 20px;
    padding: 0 5px 0 20px;
    border-bottom: 1px solid white;
    transition: all 0.3s ease;

    button {
      &:before  {
        content: '0';
        padding-right: 1px;        
      }

      outline: 0;

      position: relative;

      span {
        position: absolute;
        left: 40px;
        display: inline-block;
        color: white;
        opacity: 0;
        transition: all 0.3s ease;
        width: 145px;
        text-align: left;
      }
    }

    &.slick-active, &:hover {
      background-color:white;
      color: black; 

      button span {
        opacity: 1;
      }     
    }


  }
}

.section-title {
  z-index: 16;
  text-align: center;
  color: #e1e1e1;
  position: absolute;
  transform-origin: center right;
  right: 54px;
  transform: rotate(270deg);
  line-height: 1;
  font-size: 134px;
  font-family: 'Playfair Display', serif; 
  display: none;

  @include breakpoint(large) {
    display: block;
  }
}

#about {
  position: relative;
  overflow: hidden;
  

  .section-title {
    top: 22%;
  }

  .vertical-text {
    top: 177px;
    left: 40px;
  }

  .vertical-text2 {
    top: auto;
    bottom: -10px;
    left: 40px;
  }

}

.align-right {
  text-align: right;
}

.idx {
  font-family: 'Playfair Display', serif;
  font-size: 65px;  
  line-height: 1;

  hr {
    margin: 8px 0 0;
  }
}

.arrows {
  position: absolute;  
  z-index: 15;

  button {    
    text-indent: -9999px;
    
    overflow:hidden;
    width: 40px;
    height: 40px;    
    background: url('/assets/images/arrow.png') #666666 center center no-repeat;
    background-size: 12px 20px;
    display: inline-block;


    &.slick-next {
      transform: rotate(180deg);
      transform-origin: center center;
      border-right: 1px solid white;
    }
  }

}

#process {

  .arrows {
    top: 0;
    left: auto;
    right: 0;
    @include breakpoint(large) {
      top: 360px;
      left: calc(10% + 45px);
    }   
  }


  .slides {
    height: 115vh;
    overflow-y: visible;

    @include breakpoint(medium) {
      height: 405px;
    }

    @include breakpoint(large) {
      height: 555px;
    }   

    .slick-list {
      overflow-y: visible;
      height: 100%;
    }
  }

  .slide {
    height: 530px;
    overflow-y: visible;
    position: relative;
    @include breakpoint(medium) {
      height: 400px;
    }   
    .content-left {
      width: 17%;      
      float: left;
      background-color:black;
      height: 100%;
      padding-top: 34px;   

      @include breakpoint(medium) {
        width: 8%;
      }

      @include breakpoint(large) {
        width: 10%;
        float: left;
        background-color:black;
        height: 100%;
        padding-top: 34px;   
      }   
    }
    .content-right {
      padding-top: 60px;
      padding-left: 25px;
      width:83%;
      float: left;
      background-color:black;
      height: 100%;

      @include breakpoint(medium) {
        width:44%;
      }


      @include breakpoint(large) {
        padding-top: 60px;
        padding-left: 45px;
        width:65%;
        float: left;
        background-color:black;
        height: 100%;
      }   
    }

    .slide-img {
      width: 100%;
      position: absolute;
      left: 0;
      top: 100%;

      @include breakpoint(medium) {
        width: 48%;
        right: 0;
        top: 0;
        left: auto;
      }

      @include breakpoint(large) {
        width: 35%;
        position: absolute;
        left: 60%;
        top: 50%;
      }   
    }
    p {
      width: 90%;
      @include breakpoint(large) {
        width: 60%;
      }   
    }
  }
}

#projects {

  .arrows {
    top: 0;
    left: auto;
    right: 0;
    @include breakpoint(large) {
      top: 70vh;
      left:50%;
      margin-left: -80px;      
    }   
  }

  .slides {    
    
    .slide-content {
      width: 100%;
      height: calc(80vh);  
      @include breakpoint(large) {
        height: calc(70vh);   
      }              
    }

    .slick-dots {
      top: 5%;
      transform: translateY(0);
    }

    .slide-content1 {
      background-image: url('/assets/images/render/1.jpg');
    }
    .slide-content2 {
      background-image: url('/assets/images/project/frameworks.jpg');
    }
    .slide-content3 {
      background-image: url('/assets/images/project/knight.jpg');
    }
    .slide-content4 {
      background-image: url('/assets/images/project/4.jpg');
    }
    .slide-content5 {
      background-image: url('/assets/images/project/5.jpg');
    }

    .slick-active {
      .slide-content {
        // animation: move 60s ease;  
      }
    }
  }   

  .project-content {    
    width: 100%;
    margin-top: 0;
    padding: 40px;
    background-color: rgba(222,222,222,0.95);
    float:right;
    &:after {
      content: '';
      height: 0;
      clear:both;
      display: table;
    }

    @include breakpoint(large) {
      width: 50%;
      margin-top: -180px;
    }

    p, li, a {
      font-size: 14px;
      line-height:23px;
      color: #000;
      font-family: 'gotham book';

      &.quote {
        font-family: 'Playfair Display', serif;
        font-size: 20px;
        line-height: 1.2;
        width: 50%;
      }
    }
  }

}

.quote {
  font-family: 'Playfair Display', serif;  
  line-height: 1.5;
  font-size: 20px;
}

#community {
  padding-top: 100px;

  .section-title {
    top: 0;
  }

  .vertical-text {
    top: 121px;
    left: 40px;
  }
}

#media {
  padding-top: 50px;
  @include breakpoint(large) {
    padding-top: 97px;
  }

  .vertical-text {
    top: 87px;
    left: 40px;
  }

  .section-title {
    top: 15%;
    opacity: 0.2;
  }
  .arrows {
    bottom: 0;
    top: auto;
    left: 50%;
  }
  .slides {
    background-color: #086c98;
    height: 800px;
    @include breakpoint(large) {
      height: 500px;
    }
  }
  .slide, .slick-list, .slick-track {
    height: 100%;
  }
  .columns {
    height: 100%;
  }
  .media-content {
    width: 100%;
    float: left;

    @include breakpoint(large) {
      width: 50%;
    }

    .content-left {
      width: 17%;      
      float: left;      
      height: 100%;
      padding-top: 34px;   
      @include breakpoint(large) {
        width: 16%;
        float: left;        
        height: 100%;
        padding-top: 34px;   
      }   
    }
    .content-right {
      padding-top: 60px;
      padding-left: 25px;
      width:83%;
      float: left;      
      height: 100%;
      @include breakpoint(large) {
        padding-top: 60px;
        padding-left: 45px;
        width:65%;
        float: left;        
        height: 100%;
      }   
    }
  }
  .media-img {
    width: 100%;
    height: 50%;
    float: left;
    @include breakpoint(large) {
      width: 50%;
      height: 100%;
    }
  }
  .media-img1 {    
    background:url('/assets/images/render/1.jpg') center center no-repeat;
    background-size: cover;
  }
  .media-img2 {    
    background:url('/assets/images/render/1.jpg') center center no-repeat;
    background-size: cover;
  }
  .media-img3 {    
    background:url('/assets/images/render/2.jpg') center center no-repeat;
    background-size: cover;
  }
  .media-img4 {    
    background:url('/assets/images/render/2.jpg') center center no-repeat;
    background-size: cover;
  }
}

#contact {  
  padding-top: 50px;

  @include breakpoint(large) {
    padding-top: 160px;
  }

  .vertical-text {
    top: 151px;
    left: 40px;
  }

  #map {    
    width: 100%;
    height:300px;
    float: left;

    @include breakpoint(large) {
      width: 60%;
      height: 450px;
    }
  }

  .contact-info {
    float: left;
    background-color: black;
    margin-left: 0;
    width:100%;    
    position: relative; 
    padding: 50px;
    margin-left: 0;
    margin-top: 0;

    @include breakpoint(large) {
      width:40%;      
      width:40%;    
      position: relative; 
      padding: 50px;
      margin-left: -200px;
      margin-top: 120px;
    }
  }

}