
div.cs-skin-rotate {
	background: transparent;
	font-size: 1.5em;
	font-weight: 700;
	width: 300px;
	-webkit-perspective: 1000px;
	perspective: 1000px;

	text-align: left;		
	font-family: Questrial-Regular;
	font-size: 12px;
	color: #67615C;
	letter-spacing: 2px;
	width: 100%;
}

@media screen and (max-width: 30em) {
	div.cs-skin-rotate { 		
		
	}	
}

.cs-skin-rotate > span,
.cs-skin-rotate .cs-options li {
	background: white;
	-webkit-transform-origin: 50% 50% -200px;
	transform-origin: 50% 50% -200px;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
}

@media screen and (max-width: 30em) {
	.cs-skin-rotate > span,
	.cs-skin-rotate .cs-options li {
		-webkit-transform-origin: 50% 50% -100px;
		transform-origin: 50% 50% -100px;
	}
}

.cs-skin-rotate > span {
	border: 1px solid #C3A373;
}

.cs-skin-rotate.cs-active > span {
	opacity: 0;
	-webkit-transform: rotate3d(0,1,0,-90deg);
	transform: rotate3d(0,1,0,-90deg);
}

.cs-skin-rotate > span::after,
.cs-skin-rotate .cs-selected span::after {
	// font-family: 'icomoon';
}

.cs-skin-rotate > span::after,
.cs-skin-rotate.cs-active > span::after {
	content: '';
	background: url('../images/arrow_down.png') center center no-repeat;
	width: 17px;
	height: 9px;	
}

.cs-skin-rotate ul span::after {
	content: '';
	opacity: 0;
}

.cs-skin-rotate .cs-selected span::after {	
	font-size: 1.5em;
	opacity: 0.5;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
}

.cs-skin-rotate .cs-options {
	background: transparent;
	top: 0;
	overflow: visible;
	visibility: visible;
	pointer-events: none;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.cs-skin-rotate .cs-options ul {
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;	
}

.cs-skin-rotate .cs-options li {
	opacity: 0;
	-webkit-transform: rotate3d(0,1,0,90deg);
	transform: rotate3d(0,1,0,90deg);

	border-left: 1px solid #C3A373;
	border-top: 1px solid #C3A373;
	border-right: 1px solid #C3A373;

	&:last-child {
		border-bottom: 1px solid #C3A373;		
	}
}

.cs-skin-rotate.cs-active .cs-options li {
	opacity: 1;
	-webkit-transform: rotate3d(0,1,0,0deg);
	transform: rotate3d(0,1,0,0deg);
	pointer-events: auto;
}

.cs-skin-rotate .cs-options li:nth-child(2) {
	-webkit-transition-delay: 0.05s;
	transition-delay: 0.05s;
}

.cs-skin-rotate .cs-options li:nth-child(3) {
	-webkit-transition-delay: 0.1s;
	transition-delay: 0.1s;
}

.cs-skin-rotate .cs-options li:nth-child(4) {
	-webkit-transition-delay: 0.15s;
	transition-delay: 0.15s;
}

.cs-skin-rotate .cs-options li:nth-child(5) {
	-webkit-transition-delay: 0.2s;
	transition-delay: 0.2s;
}

.cs-skin-rotate .cs-options li:nth-child(6) {
	-webkit-transition-delay: 0.25s;
	transition-delay: 0.25s;
}

.cs-skin-rotate .cs-options li:nth-child(7) {
	-webkit-transition-delay: 0.3s;
	transition-delay: 0.3s;
}

.cs-skin-rotate .cs-options li:nth-child(8) {
	-webkit-transition-delay: 0.35s;
	transition-delay: 0.35s;
}


.cs-skin-rotate.cs-active .cs-options ul li {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

.cs-skin-rotate .cs-options li span {
	padding: 1em 1em;
}

.cs-skin-rotate .cs-options li span:hover,
.cs-skin-rotate .cs-options li.cs-focus span,
.cs-skin-rotate .cs-selected span {
	opacity: 0.8;
	background: transparent;
}

.cs-active .cs-options {
	// border: 1px solid #C3A373;
	
	ul {
		// background-color: white;	
	}
}