/// @file This is a manifest file that'll be compiled into application.css,
///       which will bootstrap all stylesheets used in the app.
///
///       You're free to add application-wide styles to this file and they'll
///       appear at the bottom of the compiled file so the styles you add here
///       take precedence over styles defined in any styles defined in the other
///       CSS/SCSS files in this directory. It is generally better to create a
///       new file per style scope.

@import 'main';
@import 'foundation';
@import 'base/_mixins.scss';
@import 'base/cs-select.scss';
@import 'base/cs-skin-rotate.scss';
@import 'base/definitions.scss';
@import 'base/layout.scss';
@import 'base/palette.scss';
@import 'base/typography.scss';

/* No files to import found in common//* */
@import 'views/register.scss';
@import 'views/welcome.scss';

@import 'default-skin/default-skin.scss';
