/// @file This file defines all global font/text related rules.
 @font-face {
    font-family: 'gotham black';
    src: 
        url('/assets/fonts/gotham-black-webfont.eot') format('eot'),        
        url('/assets/fonts/gotham-black-webfont.svg') format('svg'),
        url('/assets/fonts/gotham-black-webfont.ttf') format('truetype'),
        url('/assets/fonts/gotham-black-webfont.woff') format('woff');
  }

@font-face {
    font-family: 'gotham bold';
    src: 
        url('/assets/fonts/gotham-bold-webfont.eot') format('eot'),        
        url('/assets/fonts/gotham-bold-webfont.svg') format('svg'),
        url('/assets/fonts/gotham-bold-webfont.ttf') format('truetype'),
        url('/assets/fonts/gotham-bold-webfont.woff') format('woff');
  }

@font-face {
    font-family: 'gotham book';
    src: 
        url('/assets/fonts/gotham-book-webfont.eot') format('eot'),        
        url('/assets/fonts/gotham-book-webfont.svg') format('svg'),
        url('/assets/fonts/gotham-book-webfont.ttf') format('truetype'),
        url('/assets/fonts/gotham-book-webfont.woff') format('woff');
  }

@font-face {
    font-family: 'gotham light';
    src: 
        url('/assets/fonts/gotham-light-webfont.eot') format('eot'),        
        url('/assets/fonts/gotham-light-webfont.svg') format('svg'),
        url('/assets/fonts/gotham-light-webfont.ttf') format('truetype'),
        url('/assets/fonts/gotham-light-webfont.woff') format('woff');
  }

@font-face {
    font-family: 'gotham medium';
    src: 
        url('/assets/fonts/gotham-medium-webfont.eot') format('eot'),        
        url('/assets/fonts/gotham-medium-webfont.svg') format('svg'),
        url('/assets/fonts/gotham-medium-webfont.ttf') format('truetype'),
        url('/assets/fonts/gotham-medium-webfont.woff') format('woff');
  }
